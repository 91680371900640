import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

type CandidateRequestModel = {
    name: string;
    familyName: string;
    contact: string;
    cvCoverName: string;
    cvCoverInBase64: string;
    cvFileName: string;
    cvInBase64: string;
    city: string;
    postcode: string;
    telephoneNumber: string;
    birthDay: string;
    birthMonth: string;
    birthYear: string;
    fiscalCode: string;
    nationality: string;
    studyLevel: string;
    languages: string;
    jobTitle: string;
    yearsOfExperience: string;
    currentPosition: string;
    currentWorkingArea: string;
    levelOrSeniority: string;
};

type CandidateSupplierRequestModel = {
    id: string;
    username: string;
    password: string;
};

type JobApplicationRequestModel = {
    creationDateTime: string;
    jobId: string;
    jobTitle: string;
    candidateSupplier: CandidateSupplierRequestModel;
    candidate: CandidateRequestModel;
};

interface JobApplicationProps {
    jobTitle: string;
    privacyPolicyLinks: string[];
    positionId: string;
    companyId: string;
    jobId: string;
}

const JobApplication: React.FC<JobApplicationProps> = ({ jobTitle, privacyPolicyLinks, companyId: companyId, positionId: positionId, jobId: jobId }) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const customerId = params.get('customerId') || '';
    const intl = useIntl();

    const [name, setName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [resumeFile, setResumeFile] = useState<File | null>(null);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [resume, setResume] = useState<string | ArrayBuffer | null>(null);
    const [thankYouMessage, setThankYouMessage] = useState('');
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
    const [privacyConsent, setPrivacyConsent] = useState(false);

    const [city, setCity] = useState('');
    const [postcode, setPostcode] = useState('');
    const [birthDay, setBirthDay] = useState('');
    const [birthMonth, setBirthMonth] = useState('');
    const [birthYear, setBirthYear] = useState('');
    const [fiscalCode, setFiscalCode] = useState('');
    const [nationality, setNationality] = useState('');
    const [studyLevel, setStudyLevel] = useState('');
    const [languages, setLanguages] = useState('');
    const [currentPosition, setCurrentPosition] = useState('');
    const [yearsOfExperience, setYearsOfExperience] = useState('');
    const [currentWorkingArea, setCurrentWorkingArea] = useState('');
    const [levelOrSeniority, setLevelOrSeniority] = useState('');
    const [validationError, setValidationError] = useState<string | null>(null);

    const [loading, setLoading] = useState(false);

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type === 'application/pdf') {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = (reader.result as string).split(',')[1];
                setResume(base64String);
                setResumeFile(file);
            };
            reader.readAsDataURL(file);
        } else {
            alert(intl.formatMessage({ id: "carrierPageApply.uploadPdf", defaultMessage: "Please upload a PDF file." }));
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files?.[0];
        if (file && file.type === 'application/pdf') {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = (reader.result as string).split(',')[1];
                setResume(base64String);
                setResumeFile(file);
            };
            reader.readAsDataURL(file);
        } else {
            alert(intl.formatMessage({ id: "carrierPageApply.uploadPdf", defaultMessage: "Please upload a PDF file." }));
        }
    };

    const errorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (validationError && errorRef.current) {
            errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [validationError]);

    const validateForm = (): string | null => {
        if (!name.trim()) return intl.formatMessage({ id: "carrierPageApply.validation.name", defaultMessage: "Full Name is required" });
        if (!familyName.trim()) return intl.formatMessage({ id: "carrierPageApply.validation.familyName", defaultMessage: "Surname is required" });
        if (!email.trim()) return intl.formatMessage({ id: "carrierPageApply.validation.email", defaultMessage: "Email is required" });
        if (!/^\S+@\S+\.\S+$/.test(email)) return intl.formatMessage({ id: "carrierPageApply.validation.emailFormat", defaultMessage: "Invalid email format" });
        if (!phone.trim()) return intl.formatMessage({ id: "carrierPageApply.validation.phone", defaultMessage: "Phone is required" });
        if (!/^\+?[\d\s-]{7,}$/.test(phone)) return intl.formatMessage({ id: "carrierPageApply.validation.phoneFormat", defaultMessage: "Invalid phone format" });
        if (!resumeFile) return intl.formatMessage({ id: "carrierPageApply.validation.resume", defaultMessage: "Resume is required" });
        if (!privacyConsent) return intl.formatMessage({ id: "carrierPageApply.validation.privacyConsent", defaultMessage: "Please agree to the privacy policy" });

        // Add more validations for additional fields if needed
        return null;
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const validationError = validateForm();
        if (validationError) {
            setValidationError(validationError);
            return;
        }

        setValidationError(null);
        setLoading(true);

        const creationDateTime = new Date().toISOString();
        const candidateSupplier: CandidateSupplierRequestModel = {
            id: customerId,
            username: "",
            password: ""
        };

        const candidate: CandidateRequestModel = {
            name: name.split(' ')[0],
            familyName: familyName,
            contact: email,
            cvFileName: resumeFile ? resumeFile.name : "",
            cvInBase64: resume ? resume.toString() : "",
            telephoneNumber: phone,
            cvCoverName: "",
            cvCoverInBase64: "",
            city: city,
            postcode: postcode,
            birthDay: birthDay,
            birthMonth: birthMonth,
            birthYear: birthYear,
            fiscalCode: fiscalCode,
            yearsOfExperience: yearsOfExperience,
            nationality: nationality,
            studyLevel: studyLevel,
            languages: languages,
            jobTitle: jobTitle,
            currentPosition: currentPosition,
            currentWorkingArea: currentWorkingArea,
            levelOrSeniority: levelOrSeniority
        };

        // const apiUrl = `https://app.matchtal.com/getJobPostById`;
        const apiUrl = process.env.REACT_APP_API_URL + "/postCandidateApplicants";
        const apiKey = process.env.REACT_APP_GET_JOBPOSTS_SERVER_API_KEY;

        const jobApplicationRequest: JobApplicationRequestModel = {
            jobId: companyId + "__" + positionId + "__" + jobId,
            jobTitle: jobTitle,
            creationDateTime: creationDateTime,
            candidateSupplier: candidateSupplier,
            candidate: candidate
        };

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify(jobApplicationRequest)
            });

            if (response.ok) {
                setThankYouMessage(intl.formatMessage({ id: "carrierPageApply.successMessage", defaultMessage: "Application submitted successfully!" }));
            } else {
                alert(intl.formatMessage({ id: "carrierPageApply.errorMessage", defaultMessage: "Failed to submit application. Please try again later.'" }));
            }
        } catch (error) {
            console.error('Error submitting application:', error);
            alert('Failed to submit application. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="w-full max-w-lg bg-white p-8 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-6">{intl.formatMessage({ id: "carrierPageApply.applyForJob", defaultMessage: "Apply for Job" })}</h2>
                {thankYouMessage ? (
                    <div className="text-green-500">{thankYouMessage}</div>
                ) : (
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.firstName", defaultMessage: "First Name:" })}</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                                disabled={loading}
                            />
                            {validationError && !name.trim() && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "carrierPageApply.validation.name", defaultMessage: "First Name is required" })}
                                </p>
                            )}
                        </div>
                        <div>
                            <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.surname", defaultMessage: "Surname:" })}</label>
                            <input
                                type="text"
                                value={familyName}
                                onChange={(e) => setFamilyName(e.target.value)}
                                className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                                disabled={loading}
                            />
                            {validationError && !familyName.trim() && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "carrierPageApply.validation.surname", defaultMessage: "Surname is required" })}
                                </p>
                            )}
                        </div>
                        <div>
                            <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.email", defaultMessage: "Email:" })}</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                                disabled={loading}
                            />
                            {validationError && !email.trim() && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "carrierPageApply.validation.email", defaultMessage: "Email is required" })}
                                </p>
                            )}
                            {validationError && email.trim() && !/^\S+@\S+\.\S+$/.test(email) && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "carrierPageApply.validation.emailFormat", defaultMessage: "Invalid email format" })}
                                </p>
                            )}

                        </div>
                        <div>
                            <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.phoneNumber", defaultMessage: "Phone:" })}</label>
                            <input
                                type="tel"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                                disabled={loading}
                            />
                            {validationError && !phone.trim() && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "carrierPageApply.validation.phone", defaultMessage: "Phone is required" })}
                                </p>
                            )}
                            {validationError && phone.trim() && !/^\+?[\d\s-]{7,}$/.test(phone) && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "carrierPageApply.validation.phoneFormat", defaultMessage: "Invalid phone format" })}
                                </p>
                            )}
                        </div>
                        <div>
                            <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.uploadResume", defaultMessage: "Upload Resume (PDF):" })}</label>
                            <div
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={handleFileDrop}
                                className="text-center w-full py-8 border-dashed border-2 border-gray-400 rounded-lg flex justify-center items-center cursor-pointer"
                                onClick={() => fileInputRef.current?.click()}
                            >
                                {resumeFile ? (
                                    <span className='text-green-500'>{resumeFile.name}</span>
                                ) : (
                                    <span>{intl.formatMessage({ id: "carrierPageApply.dragAndDropCv", defaultMessage: "Drop your resume or click here" })}</span>
                                )}
                            </div>
                            <input
                                type="file"
                                accept="application/pdf"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                className="hidden"
                                disabled={loading}
                            />
                            {validationError && !resumeFile && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "carrierPageApply.validation.resume", defaultMessage: "Resume is required" })}
                                </p>
                            )}
                        </div>
                        {/* <div>
                            <label className="block text-left mb-2 text-gray-700">Cover Letter:</label>
                            <textarea
                                value={coverLetter}
                                onChange={(e) => setCoverLetter(e.target.value)}
                                rows={5}
                                className="w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div> */}
                        <div className="flex justify-start">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={showAdditionalInfo}
                                    onChange={() => setShowAdditionalInfo(!showAdditionalInfo)}
                                />
                                <span className="slider round"></span>
                            </label>
                            <span className="text-gray-700">{intl.formatMessage({ id: "carrierPageApply.addMoreInformation", defaultMessage: "Add more information" })}</span>
                        </div>
                        {showAdditionalInfo && (
                            <>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.city", defaultMessage: "City:" })}</label>
                                    <input
                                        type="text"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.postcode", defaultMessage: "Postcode:" })}</label>
                                    <input
                                        type="text"
                                        value={postcode}
                                        onChange={(e) => setPostcode(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.birthDay", defaultMessage: "Birth Day:" })}</label>
                                    <input
                                        type="text"
                                        value={birthDay}
                                        onChange={(e) => setBirthDay(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.birthMonth", defaultMessage: "Birth Month:" })}</label>
                                    <input
                                        type="text"
                                        value={birthMonth}
                                        onChange={(e) => setBirthMonth(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.birthYear", defaultMessage: "Birth Year:" })}</label>
                                    <input
                                        type="text"
                                        value={birthYear}
                                        onChange={(e) => setBirthYear(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.fiscalCode", defaultMessage: "Fiscal Code:" })}</label>
                                    <input
                                        type="text"
                                        value={fiscalCode}
                                        onChange={(e) => setFiscalCode(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.nationality", defaultMessage: "Nationality:" })}</label>
                                    <input
                                        type="text"
                                        value={nationality}
                                        onChange={(e) => setNationality(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.studyLevel", defaultMessage: "Study Level:" })}</label>
                                    <input
                                        type="text"
                                        value={studyLevel}
                                        onChange={(e) => setStudyLevel(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.languages", defaultMessage: "Languages:" })}</label>
                                    <input
                                        type="text"
                                        value={languages}
                                        onChange={(e) => setLanguages(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.currentPosition", defaultMessage: "Current Position:" })}</label>
                                    <input
                                        type="text"
                                        value={currentPosition}
                                        onChange={(e) => setCurrentPosition(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.yearsOfExperience", defaultMessage: "Years Of Experience:" })}</label>
                                    <input
                                        type="text"
                                        value={yearsOfExperience}
                                        onChange={(e) => setYearsOfExperience(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.currentWorkingArea", defaultMessage: "Current Working Area:" })}</label>
                                    <input
                                        type="text"
                                        value={currentWorkingArea}
                                        onChange={(e) => setCurrentWorkingArea(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "carrierPageApply.levelOrSeniority", defaultMessage: "Level or Seniority:" })}</label>
                                    <input
                                        type="text"
                                        value={levelOrSeniority}
                                        onChange={(e) => setLevelOrSeniority(e.target.value)}
                                        className="text-left w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                            </>
                        )}
                        <div>
                            <div className="flex justify-start mb-4">
                                <input
                                    type="checkbox"
                                    id="privacyConsent"
                                    className="mr-2"
                                    checked={privacyConsent}
                                    onChange={(e) => {
                                        setPrivacyConsent(e.target.checked);
                                        if (e.target.checked) {
                                            setValidationError(null);
                                        }
                                    }}
                                />
                                <label htmlFor="privacyConsent" className="text-gray-700 text-left">
                                    {intl.formatMessage({
                                        id: "carrierPageApply.privacyConsent",
                                        defaultMessage: "I agree to the privacy policy"
                                    })}
                                </label>
                            </div>

                            <div className='flex justify-start'>
                                {privacyPolicyLinks.map((link, index) => (
                                    <React.Fragment key={index}>
                                        <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                            {`Policy ${index + 1}`}
                                        </a>
                                        {index < privacyPolicyLinks.length - 1 && <span> - </span>}
                                    </React.Fragment>
                                ))}
                            </div>
                            {validationError && !privacyConsent && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "carrierPageApply.validation.privacyConsent", defaultMessage: "Please agree to the privacy policy" })}
                                </p>
                            )}
                        </div>
                        <div className="text-center mt-6">
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 w-full rounded-full transition duration-150 ease-in-out no-underline text-sm"
                                disabled={loading}
                            >
                                {loading ? intl.formatMessage({ id: "carrierPageApply.loading", defaultMessage: "Submitting..." }) : intl.formatMessage({ id: "carrierPageApply.submit", defaultMessage: "Submit" })}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default JobApplication;
