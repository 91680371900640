import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { convertFirestoreTimestampToDate } from '../utils/date-utils';
import { FaMapMarkerAlt, FaRegCalendarAlt, FaTag, FaLink, FaTwitter, FaLinkedin, FaFacebook } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import JobApplication from '../components/carrier-page/job-application';

interface JobDetail {
    id: string;
    title: string;
    date: string;
    location: string;
    description: string;
    category: string;
    positionId: string;
    privacyPolicyLinks: string[];
}

const JobDetailPage: React.FC = () => {
    const { customerId: customerId, jobId: jobId, sourceCode } = useParams<{ customerId: string; jobId: string; sourceCode: string }>();
    const [job, setJob] = useState<JobDetail | null>(null);
    const [copied, setCopied] = useState(false);
    const intl = useIntl();

    // const apiUrl = `https://app.matchtal.com/getJobPostById`;
    const apiUrl = process.env.REACT_APP_API_URL + "/getJobPostById";
    const apiKey = process.env.REACT_APP_GET_JOBPOSTS_SERVER_API_KEY;

    useEffect(() => {
        const fetchJobDetail = async () => {
            try {
                const response = await fetch(`${apiUrl}?companyId=${customerId}&jobId=${jobId}&sourceCode=${sourceCode}`, { //TODO: implement in backend tracking of source board
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`
                    },
                });
                const fetchedJob = await response.json();

                const formattedJob = {
                    ...fetchedJob,
                    date: convertFirestoreTimestampToDate(fetchedJob.date._seconds, fetchedJob.date._nanoseconds).toLocaleDateString(),
                };

                setJob(formattedJob);
            } catch (error) {
                console.error('Error fetching job detail:', error);
            }
        };

        fetchJobDetail();
    }, [customerId, jobId]);

    if (!job) {
        return <div className="flex justify-center items-center h-screen">{intl.formatMessage({ id: "global.loading", defaultMessage: "Loading..." })}</div>;
    }

    const pageUrl = window.location.href;

    return (
        <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row gap-8 px-4 py-8">
                <div className="lg:w-2/3">
                    <h1 className="text-4xl font-bold mb-4 text-left" >{job.title}</h1>
                    <div className="bg-blue-50 p-4 rounded-lg flex items-center justify-between mb-4">
                        <div className="flex items-center">
                            <FaMapMarkerAlt className="text-gray-500 mr-2" />
                            <span className="text-gray-700">{job.location}</span>
                        </div>
                        <span className="text-gray-600"> <FaTag className="text-gray-500 mr-2" />{job.category}</span>
                        <span className="text-gray-600"> <FaRegCalendarAlt className="text-gray-500 mr-2" />{job.date}</span>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2 text-left">{intl.formatMessage({ id: "carrierPageDetail.jobDescription", defaultMessage: "Job Description" })}</h2>
                        <p className="text-gray-700 whitespace-pre-wrap text-left">{job.description}</p>
                    </div>
                    <div className="flex items-center space-x-4">
                        <CopyToClipboard text={pageUrl} onCopy={() => setCopied(true)}>
                            <button className="flex items-center bg-gray-200 hover:bg-gray-300 px-3 py-2 rounded">
                                <FaLink className="mr-2" />
                                {copied ? 'Copied!' : 'Copy Link'}
                            </button>
                        </CopyToClipboard>
                        <a href={`https://twitter.com/intent/tweet?url=${pageUrl}`} target="_blank" rel="noopener noreferrer" className="bg-blue-400 hover:bg-blue-500 text-white px-3 py-2 rounded">
                            <FaTwitter />
                        </a>
                        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`} target="_blank" rel="noopener noreferrer" className="bg-blue-700 hover:bg-blue-800 text-white px-3 py-2 rounded">
                            <FaLinkedin />
                        </a>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`} target="_blank" rel="noopener noreferrer" className="bg-blue-600 hover:bg-blue-700 text-white px-3 py-2 rounded">
                            <FaFacebook />
                        </a>
                    </div>
                </div>
                <div className="lg:w-1/3">
                    <JobApplication jobTitle={job.title} privacyPolicyLinks={job.privacyPolicyLinks} positionId={job.positionId} companyId={customerId ?? ""} jobId={jobId ?? ""} />
                </div>
            </div>
        </div>
    );
};

export default JobDetailPage;