import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { convertFirestoreTimestampToDate } from '../utils/date-utils';
import { useIntl } from 'react-intl';
import { FaMapMarkerAlt, FaRegCalendarAlt } from 'react-icons/fa';

interface Job {
    id: string;
    title: string;
    date: string;
    location: string;
    link: string;
}

interface QueryParams {
    borderRadius?: string;
    color?: string;
    displayMode?: 'card' | 'table';
}

const JobWidget: React.FC = () => {
    const { customerId: customerId } = useParams<{ customerId: string }>();
    const location = useLocation();
    const queryParams = queryString.parse(location.search) as QueryParams;

    const [jobs, setJobs] = useState<Job[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 50;
    const intl = useIntl();

    // const apiUrl = "app.matchtal.com/getJobPostsByCompanyId";
    const apiUrl = process.env.REACT_APP_API_URL + "/getJobPostsByCompanyId";
    const apiKey = process.env.REACT_APP_GET_JOBPOSTS_SERVER_API_KEY;

    const borderRadius = queryParams.borderRadius || '0.5rem';
    const backgroundColor = queryParams.color || '#f9f9f9';
    const displayMode = queryParams.displayMode || 'card';

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await fetch(`${apiUrl}?companyId=${customerId}`, {
                    headers: {
                        'Authorization': `Bearer ${apiKey}`,
                        'Content-Type': 'application/json'
                    }
                });
                const fetchedJobs = await response.json();

                const formattedJobs = fetchedJobs.map((job: any) => ({
                    ...job,
                    date: convertFirestoreTimestampToDate(job.publishdate._seconds, job.publishdate._nanoseconds).toLocaleDateString(),
                }));

                setJobs(formattedJobs);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };

        fetchJobs();
    }, [customerId]);

    const loadMore = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const visibleJobs = jobs.slice(0, (currentPage + 1) * itemsPerPage);

    const renderCard = (job: Job) => (
        <div key={job.id} className="bg-white shadow-lg rounded-lg overflow-hidden" style={{ borderRadius }}>
            <div className="p-4">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">{job.title}</h2>
                <p className="text-sm text-gray-600 mb-1">
                    <div className="flex-col items-center">
                        <FaRegCalendarAlt className="text-gray-500 mr-2" />
                        {job.date}
                    </div>
                </p>
                <p className="text-sm text-gray-600 mb-3">
                    <div className="flex-col items-center">
                        <FaMapMarkerAlt className="text-gray-500 mr-2" />
                        {job.location}
                    </div>
                </p>
                <a href={job.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block w-full text-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded-full transition duration-150 ease-in-out no-underline"
                >
                    {intl.formatMessage({ id: "carrierPageWidget.discoverMore", defaultMessage: "Discover More" })}
                </a>
            </div>
        </div>
    );

    const renderTable = () => (
        <div className="overflow-x-auto">
            <table className="w-full bg-white shadow-lg text-left" style={{ borderCollapse: 'separate', borderSpacing: 0, border: '1px solid #e5e7eb', borderRadius: borderRadius }} >
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r" style={{ borderTopLeftRadius: borderRadius }}>
                            {intl.formatMessage({ id: "carrierPageWidget.title", defaultMessage: "Title" })}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r">
                            {intl.formatMessage({ id: "carrierPageWidget.date", defaultMessage: "Date" })}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r">
                            {intl.formatMessage({ id: "carrierPageWidget.location", defaultMessage: "Location" })}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b" style={{ borderTopRightRadius: borderRadius }}>
                            {intl.formatMessage({ id: "carrierPageWidget.details", defaultMessage: "Details" })}
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {visibleJobs.map((job, index) => (
                        <tr key={job.id} className={index % 2 === 0 ? 'bg-blue-50' : 'bg-white'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-b border-r">
                                {job.title}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b border-r">
                                <div className="flex items-center">
                                    <FaRegCalendarAlt className="text-gray-500 mr-2" />
                                    {job.date}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b border-r">
                                <div className="flex items-center">
                                    <FaMapMarkerAlt className="text-gray-500 mr-2" />
                                    {job.location}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium border-b">
                                <a href={job.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-150 ease-in-out no-underline text-sm"
                                >
                                    {intl.formatMessage({ id: "carrierPageWidget.discoverMore", defaultMessage: "Discover More" })}
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="p-4" style={{ backgroundColor }}>
            {displayMode === 'card' ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                    {visibleJobs.map(renderCard)}
                </div>
            ) : (
                renderTable()
            )}
            {jobs.length > visibleJobs.length && (
                <div className="mt-6">
                    <button
                        onClick={loadMore}
                        className="border-2 px-4 bg-white hover:bg-blue-600 text-black font-bold py-2 rounded-full transition duration-150 ease-in-out"
                    >
                        {intl.formatMessage({ id: "carrierPageWidget.discoverMore", defaultMessage: "Load More" })}
                    </button>
                </div>
            )}
        </div>
    );
}

export default JobWidget;