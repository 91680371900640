// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import VideoSection from './components/video-section';
import FeatureMatching from './components/feature-matching';
import JobPosition from './components/job-position';
import CandidateInterview from './components/candidate-interview';
import CandidateArchive from './components/candidate-archive';
import TextDivider from './components/text-devider';
import ContactUs from './components/contact-us';
import Faq from './components/faq';
import Footer from './components/footer';
import ValueProposition from './components/value-proposition';
import MoreFeatures from './components/more-features';
import Header from './components/header';
import { Hero } from './components/hero';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl';
import JobWidget from './pages/job-widget';
import Home from './pages/home';
import Layout from './Layout';
import JobDetailPage from './pages/job-detail';
import JobApplication from './components/carrier-page/job-application';

function App() {
  AOS.init();
  const intl = useIntl();

  return (
    <Router>
      <div className="App text-gray font-plus-jakarta-sans">
        <Routes>
          <Route path="/" element={<Layout><Home /></Layout>}>
          </Route>
          <Route path="/jobs/:customerId" element={<JobWidget />} />
          <Route path="/job/:customerId/:jobId" element={<JobDetailPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
